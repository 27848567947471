.pd-0 { padding: 0 !important; }
.pd-1 { padding: 10px }
.pd-4 { padding: 24px }
.pd-2 { padding: 12px }
.pd-2-5 { padding: 15px }
.pd-3 { padding: 18px }
.pd-3-5 { padding: 21px }
.pt-2 { padding-top: 12px }
.pt-5 { padding-top: 30px }
.pb-0 { padding-bottom: 0 }
.pb-2 { padding-bottom: 12px; }
.pb-2 { padding-bottom: 12px; }
.pb-5 { padding-bottom: 30px; }
.pl-1 { padding-left: 6px; }
.pl-3 { padding-left: 18px; }
.pl-5 { padding-left: 30px; }
.pr-3 { padding-right: 18px; }
.m-0 { margin: 0 !important; }
.mt-1 { margin-top: 6px; }
.ml-1 { margin-left: 6px; }
.mr-1 { margin-right: 6px; }
.mr-5 { margin-right:5px; }
.mr-10 { margin-right:10px; }
.mr-100 { margin-right:100px; }
.mt-2 { margin-top: 12px; }
.mr-2 { margin-right: 12px; }
.mt-4 { margin-top: 24px; }
.mt-5 { margin-top: 30px; }
.mt-6 { margin-top: 100px; }
.mb-0 { margin-bottom: 0px !important; }
.mb-1-5 { margin-bottom: 9px; }
.mb-2 { margin-bottom: 12px; }
.mb-6 { margin-bottom: 30px !important; }
.text-center { text-align: center }
.text-right { text-align: right }
.up4px { position: relative; bottom: 4px }
.up12px { position: relative; bottom: 12px }
.bb-1 { border-bottom: 1px solid #eee }
.bri-0 { border-right: 0 !important; }
.height-98 { height: 98.6px; }

.br-1 { border-right: 1px solid #eee; }
.bl-1 { border-left: 1px solid #eee !important; }
.item-disabled { pointer-events: none; opacity: .6; cursor: none }
.br-2 { border-radius: 8px }
.bblr-2 { border-bottom-left-radius: 8px }
.float-right{ float: right }
.down4px { position: relative; top: 4px;}
.inline-block { display: inline-block }
.cursor-point{ cursor: pointer }
.dont-point{ cursor: default }
.red { color: $red }
.pull-left { float: left }
.pull-right { float: right }

//coustom
.item-disabled { pointer-events: none; opacity: .6; cursor: none }
h2 .pull-right a {
  display: block;
}