/* login pages */
.login-page {
  height: calc(100vh - 7px);
}

/*logo block */

.logo-block {
  position: relative;
  @media (max-width: 991px) {
    height: 350px;
  }
  @media (max-width: 767px) {
    height: 300px;
  }
  @media (max-width: 374px) {
    height: 200px;
  }
  .image-block {
    img {
      height: 100vh;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  .content-block {
    text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
}

/* login form block */

.login-form {
  max-width: 337px;
  width: 100%;
  margin: 0 auto;

  .login-logo {
    margin-bottom: 85px;
    text-align: center;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }

    img {
      max-width: 332px;
      margin: 0 auto;

      @media (max-width: 767px) {
        max-width: 222px;
      }
    }
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    padding: 0 30px;
  }

  h2 {
    font-size: 13px;
    font-family: $hk-grotesk-bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 60px;
  }
  p{
    &.login-account {
      font-size: 20px;
      font-family: $hk-grotesk-bold;
      margin-bottom: 60px;
      text-align: center;
    }
    &.account-sign-up {
      font-size: 15px;
      margin-bottom: 60px;
    }
    & + p {
      margin-top: 0;
    }
  }
  .form-group {
    border: none;
    box-shadow: 0 12px 40px rgba(0,0,0,0.08);
    transition: all .2s ease-in;
    label {
      width: 100%;
      a {
        float: right;
        color: rgba(0,0,0,.3);
      }
    }
    &:focus-within{
      border-left: 4px solid $red;
      padding-left: 16px;
    }
  }
}



body {
  .content-area {
    margin-top: 45px;
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    //padding: 52px 90px 100px;
    //@media (max-width: 991px) {
    //  padding: 50px 80px;
    //}
    @media (max-width: 991px) {
      margin-top: 70px;
    }

  }
  &.status {
    .content-area {
      padding: 35px 50px;
    }
  }
}

.right-sidebar {
  @media (max-width: 991px) {
    margin-top: 60px;
  }
}


.table {
  color: $black;
  thead {
    tr {
      th {
        font-size: 12px;
        line-height: 16px;
        background: $gray2;
        &.print {
          background-color: $white;
          width: 40px;
          vertical-align: middle;
          text-align: center;
          border: none;
        }
      }
    }
  }
  tbody {
    tr {
      background-color: $white;
      td {
        font-size: 12px;
        line-height: 16px;
        &.clickable-col {
          cursor: pointer;
          font-size: 14px;
        }

        button,
        .btn-red {
          @include border-radius(0);
        }
        span {
          color: $red;

          &:not(.badge) {
            margin-left: 20px;
          }
        }
        &.approved {
          text-transform: uppercase;
          font-family: $hk-grotesk-bold;
          color: $green;
        }
        &.print {
          background-color: $white;
          width: 40px;
          vertical-align: middle;
          text-align: center;
          border: none;
          img {
            min-width: 15px;
          }
        }
      }
      &:nth-of-type(odd) {
        background-color: $white;
      }
      &:nth-of-type(even) {
        background-color: #EEEEEE;
      }
    }
  }
}

//fullscreen table layout

//filter table
.form-group {
  [class*="css-"] {
    &[class*="-container"] {
      [class*='-control'] {
        border: none;
        background: transparent;
        color: #1D1D1D;
        font-size: 13px;
        font-family: $hk-grotesk-regular;
        outline: none !important;
        box-shadow: none !important;

        > div {
          padding: 0;
        }

        [class*='-IndicatorsContainer'] {
          background : url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAJRJREFUKJHtkbsRgzAQBffUgKvQjEKVQCLHlIA7cCl04EJIXAI5CS24AHgOCPyTZZH7pe92b+YOCtExqNS7Uvkrf3gvrBRmpdDtgZRCpxRmB0sL6msF25x6WFpnwzTC2tQIHuDa2DCNDqBG8A7C08FKghwIYJ8bfAR3BTtjXBCnHJiFXwRmB6RbDixGycftjT5+m7kDHtpWgwj9EIcAAAAASUVORK5CYII=) no-repeat 100% 10px;

          span {
            background-color: transparent;
          }

          div:not(:first-child) {
            svg {
              opacity: 0;
            }
          }

        }
      }
    }

  }
}
.filter-btn-row {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  min-width: 150px;
}

//table

.table-dashboard {
  position: relative;
  @media (max-width: 991px) {
    &::after {
      content: '';
      width: 40px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(-90deg, white, rgba(255, 255, 255, 0.1));
    }
  }

  &.table-small {
    table {
      thead {
        tr {
          th {
            &:last-child {
              @media (max-width: 767px) {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }

  &:not(.table-small) {
    table {
      @media (min-width: 1025px) {
        table-layout: fixed;
      }

      @media (max-width: 1024px) {
        min-width: 150%;
      }

      @media (max-width: 991px) {
        min-width: 180%;
      }

      @media (max-width: 767px) {
        min-width: 200%;
      }

      @media (max-width: 576px) {
        min-width: 250%;
      }

      @media (max-width: 480px) {
        min-width: 350%;
      }

      @media (max-width: 360px) {
        min-width: 400%;
      }

      thead {
        tr {
          th {
            padding: 20px 5px;
            border: none;

            &:first-child {
              width: 120px;
              text-align: center;

            }

            &:last-child {
              width: 165px;
              text-align: right;
              padding-right: 15px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 13px 5px;
            vertical-align: middle;
            border: none;

            &:first-child {
              text-align: center;
            }

            &:last-child {
              text-align: right;
              padding-right: 15px;
              @media (max-width: 991px) {
                position: relative;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }

}

body {
  &.is-layout-table {
    header {
      display: block;
    }

    #sidebar {
      left: -25%;
      z-index: 15;
      transition: left 0.3s cubic-bezier(0.6, 0.84, 0.44, 0.6) 0.3s;

      @media (max-width: 991px) {
        left: -280px;
        
        .logo {
          display: block;
        }
      }
    }

    .section-wrapper {
      margin-top: 30px;

      .container-fluid {
        padding-left: 55px !important;
        padding-right: 55px !important;

        @media (max-width: 767px) {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }
      .col-container {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    &.menu-open {
      #sidebar {
        left: 0;
      }
    }
  }
}

//popup modals
.popup-modal {
  .modal-content {
    position: relative;
    padding: 50px 50px 60px 50px;
    border: none;

    @media (max-width: 767px) {
      padding: 50px 20px;
    }

    .modal-close {
      position: absolute;
      right: 30px;
      top: 20px;
    }

    .modal-inner {
      max-height: 460px;
      overflow-x: hidden;

      ul {
        margin: 0 0 30px 0;
        padding: 0;

        li {
          padding: 10px 0;
          font-size: 12px;
          color: $black;
          font-family: $hk-grotesk-bold;
          display: flex;

          &:first-child {
            font-size: 17px;
          }

          span {
            &:first-child {
              width: 40%;
            }
            width: 60%;
            font-family: $hk-grotesk-regular;
            text-align: left !important;
          }
        }
      }

      form {
        margin-bottom: 20px;
        .btn-theme {
          margin-top: 20px;
        }

        .modal-btn {
          position: absolute;
          bottom: 10px;
          right: 0;
          left: 0;
          margin: 0 auto;
          width: 144px;

          @media (max-width: 767px) {
            left: 0;
            right: 0;
            margin: 0 auto;
          }


          .btn-theme {
            background: $red;
            height: 40px;
            box-shadow: 0 6px 15px rgba(94, 145, 248, 0.4);
          }
        }
      }
    }
  }
}


/* custom column */
.custom-col {
  flex: 0 0 20%;
  max-width: 20%;

  @media (max-width: 1280px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 992px) {
    .btn-theme {
      margin-left: 0 !important;
    }

    .form-group {
      padding: 0 10px;
    }
  }

  @media (max-width: 991px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;

    &:last-child {
      &:not(.two-col-block) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
  }

  @media (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

//image uplaoder
#fileUpload {
  display: none;
}

.uploader-custom {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;

  img {
    margin-bottom: 15px;
  }
  p {
    font-size: 10px;
    font-family: $hk-grotesk-bold;
    text-transform: uppercase;
    margin-bottom: 0;

    + p {
      margin-top: 10px;
    }
    
    &.fileType {
      font-size: 12px;
      font-style: italic;
    }
  }
}

.file-list {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: count;
  li {
    position: relative;
    span {
      background-color: transparent;
      color: $black !important;
    }

    &::before {
      counter-increment: count;
      content: counter(count) '.';
      font-size: 14px;
    }
  }
}

//tab list
.tab-bar {
  .tab-bar-tab {
    @include transition(0.3s ease all);
    position: relative;
    
    @media (max-width: 480px) {
      display: block;
    }

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      background: $red;
      left: 0;
      bottom: 0;
      @include transition(0.3s ease all);
    }


    + .tab-bar-tab {
      margin-left: 30px;

      @media (max-width: 767px) {
        margin-left: 15px;
      }

      @media (max-width: 480px) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
    
    &.tab-active,
    &:hover{
      color: $red;

      &::before {
        width: 100%;
      }
    }
  }
}

//table-filter

//.table-filter {
//  padding-right: 15px;
//}

//order detail
.detail-page {
  .file-list {
    margin-bottom: 30px;
    counter-reset: count;

    li {
      font-size: 12px;
      &::before {
        counter-increment: count;
        content: counter(count) '.';
        font-size: inherit;
        top: 0;
      }
    }
  }
}

.right-sidebar  {
  text-align: right;
  a {
    &.btn-small {
      max-width: 95px;
      float: right;
    }

    + a {
      margin-left: 15px;
    }
  }
}
@media (min-width: 992px) {
  .order-detail-page {
    padding-right: 450px;
  }
}

/**
sidebar additional scss
 */
body .sidebar ul.upper-menu {
  margin-top: 35px;
}

@media (max-height: 620px) {
  body .sidebar {
    overflow-y: scroll;
  }

  body .sidebar ul.lower-menu {
    position: static;
    margin-top: 40px
  }
  .sidebar {
    scrollbar-width: none;
  }
  .sidebar::-webkit-scrollbar {
    display: none;
  }
}

.sidebar {
  -ms-overflow-style: none;
}