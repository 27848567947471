.has-error .label-text {
  color: $red;
}

h2 span.pull-right .btn-theme {
  min-width: 140px;
}

.table td,
.table th {
  vertical-align: middle;
}

.table-filter .custom-col .form-group {
  padding: 0;
}

.table-filter .custom-col .form-group label,
.table-filter .custom-col .form-group input {
  padding: 0 10px;
}

.table-filter .custom-col .form-group select {
  padding: 0 10px;
  background-position: calc(100% - 10px) 17px;
  // background-position: calc(100% - 10px) 0;
}

.btn-theme.btn-blue {
  background: #043b86;
  max-width: 176px;
}

.page-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 1199px) {
    display: block;
  }
  h2 {
    margin-bottom: 10px;
  }
  .title-button-block {
    @media (max-width: 1199px) {
      justify-content: space-between;
    }
  }
  .button-block {
    .btn-theme {
      margin-right: 15px;
      width: 140px;
      margin-bottom: 10px;
    }
    a {
      &:last-child {
        .btn-theme {
          margin-right: 0;
        }
      }
    }
  }
  .stat-block {
    @media (max-width: 1199px) {
      margin-top: 15px;
    }
  }
}

.stat-block {
  display: flex;
  flex-wrap: wrap;
  .single-stat {
    width: 150px;
    border-right: 1px solid #d6d6d6;
    padding: 4px 12px;
    margin-bottom: 10px;
    &:last-child {
      border-right: none;
    }
    .title-block {
      font-size: 10px;
      line-height: 14px;
    }
    .stat-info {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 8px;
      .number-block {
        font-family: "HKGrotesk-Bold";
        font-size: 17px;
        line-height: 22px;
      }
      .percent-block {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}

body {
  header {
    background: #043b86;
  }
}

.btn-cta-wrapper {
  .btn-theme {
    max-width: 200px !important;
    margin-top: 0 !important;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-right: 10px;
      & + .btn-theme {
        margin-left: 0 !important;
      }
    }
  }
}

table .actions {
  min-width: 190px;
  width: 190px;
}

table {
  td {
    &.actions {
      .btn,
      a {
        min-width: 34px;
      }
    }
    .badge {
      min-width: 70px;
    }
  }
}

.input-file-wrapper {
  position: relative;
  text-align: center;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 3px dashed transparentize($gray, 0.75);
  &:after {
    content: "";
    position: absolute;
    left: -2px;
    top: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid #fff;
  }
  .text-wrapper {
    text-align: center;
    span {
      font-size: 15px;
      color: transparentize($black, 0.7);
    }
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    z-index: 1;
    cursor: pointer;
  }
}

.table-filter {
  .col.custom-col {
    @media (min-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 1200px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .button-col {
    margin-left: auto;
    .button-cluster {
      display: flex;
      margin-bottom: 15px;
      margin-top: 5px;
      justify-content: flex-end;
    }
  }
}
.note-width {
  min-width: 200px;
  max-width: 200px;
}

.item-desc {
  h6 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    font-family: $hk-grotesk-light;
  }
}
.table thead tr th {
  text-transform: uppercase;
}
.select-field {
  min-height: 50px;
  border: 1px solid rgba(4, 58, 134, 0.6);
  font-size: 14px;
  padding: 5px 15px;
  color: #000;
  font-family: "Inter Semibold";

  .select__menu {
    padding-left: 0px;
    &.css-26l3qy-menu {
      z-index: 1;
    }
  }
  .css-1uccc91-singleValue {
    font-size: 14px;
    color: #000;
    font-family: "Inter Semibold";
  }
  .css-1wa3eu0-placeholder {
    font-size: 14px;
    color: #000;
    font-family: "Inter Semibold";
  }
}

.form-group {
  &.async-select {
    .select-field {
      border: none;
      padding: 0;
      padding-top: 5px;
    }
  }
}

.admin-login-btn {
  a {
    display: block;
    button {
      font-size: 14px;
      color: #fff;
      text-align: center;
      border: none;
      width: auto;
      text-decoration: none;
      font-family: "Inter Bold";
      line-height: 20px;
      display: block;
      background-color: #000 !important;

      & > img {
        margin-left: 10px;
      }
    }
  }
}
