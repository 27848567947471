$grid-columns:      12;
$screen-sm-min : 768px;
$screen-md-min : 992px;
$screen-lg-min : 1200px;

$S:     320px;
$M:     768px;
$L:     1170px;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin border-radius($border_radius...) {
  -moz-border-radius: $border_radius;
  -o-border-radius: $border_radius;
  -webkit-border-radius: $border_radius;
  border-radius: $border_radius;
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}


@mixin transform-origin ($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin placeholder {
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
}

@mixin make-grid-offset-right($class) {
  @for $index from 0 through $grid-columns {
    html[lang='ar'] {
      .offset-#{$class}-right-#{$index} {
        margin-left: percentage(($index / $grid-columns));
      }
    }
    html[lang='en'] {
      .offset-#{$class}-right-#{$index} {
        margin-right: percentage(($index / $grid-columns));
      }
    }
  }
}

@include make-grid-offset-right(xs);

@media (min-width: $screen-sm-min) {
  @include make-grid-offset-right(sm);
}

@media (min-width: $screen-md-min) {
  @include make-grid-offset-right(md);
}

@media (min-width: $screen-lg-min) {
  @include make-grid-offset-right(lg);
}
