* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

@media (min-width: 12000px) {
  .container {
    max-width: 1120px;
  }
}

.row {
  margin-left: -$grid-gutter-width;
  margin-right: -$grid-gutter-width;

  &.no-gutters {
    margin-left: 0;
    margin-right: 0;
  }
}

.row > * {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  margin: 0;
  overflow-x: hidden;
  font-family: $hk-grotesk-regular;
  font-size: $size16;
  color: $theme-color;
  line-height: 24px;
  //border-top: 7px solid $red;
  border-top: 7px solid $dark-blue;

  &.active,
  &.overflow-hidden {
    overflow: hidden;
  }
  &.menu-active {
    overflow: hidden;
    left: 280px;
    position: relative;
  }

  &.menu-open {
    #sidebar {
      left: 0;
    }
  }
}

a {
  text-decoration: none;
  @include transition(0.3s ease all);

  display: inline-block;
  color: $theme-color;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  transition: max-width 0.3s ease-out; // note that
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $hk-grotesk-bold;
}

h1 {
  font-size: $size50 + $size50;
  line-height: 100px;
}

h2 {
  font-size: 38px;
  line-height: 45px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    font-size: 26px !important;
    line-height: normal;
    margin-bottom: 15px;
  }

  span {
    @media (max-width: 480px) {
      float: none !important;
    }
  }

  button {
    &.btn-theme {
      margin-top: 5px;

      @media (max-width: 480px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

h4 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 30px;
}

h6 {
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 0;
}

p {
  font-size: 13px;
  line-height: 17px;
  font-family: $hk-grotesk-regular;
  margin-bottom: 30px;
  color: $theme-color;
  strong {
    font-family: $hk-grotesk-bold;
  }
}

body {
  .sidebar {
    //background: $black1;
    background: $dark-blue;
    padding: 60px 60px 0;
    position: fixed;
    left: 0;
    //width: 25%;
    width: 16.66667%;
    height: 100%;
    top: 0;
    //border-top: 7px solid $red;
    border-top: 7px solid $dark-blue;
    transition: left 0.3s cubic-bezier(0.6, 0.84, 0.44, 0.6) 0.3s;
    z-index: 4;

    @media (max-width: 1280px) {
      padding: 30px 30px 0;
    }
    a.logo {
      @media (max-width: 991px) {
        display: none;
      }
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        a {
          color: $white;
          font-size: $size16;
          line-height: 22px;

          span {
            display: block;
          }
        }
        & + li {
          margin-top: 8px;
        }
        &.active {
          a {
            color: $gray;
            text-decoration: underline;
          }
        }
      }
      &.upper-menu {
        margin-top: 90px;
        a {
          font-family: $hk-grotesk-medium;
        }
      }
      &.lower-menu {
        position: absolute;
        bottom: 60px;
        li {
          &:last-child {
            margin-top: 30px;
          }
        }
      }
    }
  }
  &:not(.status) {
    .sidebar {
      @media (max-width: 991px) {
        left: -280px;
        width: 280px;
        padding: 30px 35px 0;
        height: 100%;
      }
      &.active {
        left: 0;
        z-index: 9;
      }
    }
  }
  &.status {
    .sidebar {
      @media (min-width: 0px) {
        left: -280px;
        width: 280px;
        padding: 30px 35px 0;
        height: 100%;
        top: 0;
      }
      a.logo {
        display: none;
      }
      ul {
      }
      &.active {
        left: 0;
        z-index: 9;
      }
    }
  }
}

/* sidebar responsive */

@media (max-width: 767px) and (orientation: landscape) {
  body .sidebar ul.upper-menu {
    margin-top: 40px;
  }
  body .sidebar ul.lower-menu {
    bottom: 20px;
  }
}

.form-group {
  border: 1px solid rgba(183, 183, 183, 0.4);
  padding: 0 20px;
  margin-bottom: 15px;
  &.has-error {
    border: 1px solid $red;
    &:focus-within {
      border-left: 4px solid $red;
    }
  }
  label {
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: $black;
    font-family: $hk-grotesk-bold;
    margin-bottom: 0;
    &.has-error {
      color: $red;
    }
  }
  .form-control {
    border: none;
    padding: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:disabled {
      background: transparent;
    }
    label {
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: $black;
      font-family: $hk-grotesk-bold;
    }
  }

  textarea {
    padding: 15px 0 !important;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../../images/arrow.png") no-repeat 100% 0%;
  }
  &.upload-file {
    background: url("../../images/file-bg.png") no-repeat 50% 50%;

    padding: 12px;
    .input-file-wrapper {
      border: 1px solid rgba(183, 183, 183, 0.4);
      border-top: none;
      input {
        opacity: 0;
        padding: 35px 0;
      }
    }
  }
  &:focus-within {
    border-left: 4px solid $black;
    padding-left: 17px;
  }
}

//margins
body {
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-25 {
    margin-top: 25px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-35 {
    margin-top: 35px;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mt-45 {
    margin-top: 45px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mt-58 {
    margin-top: 58px;
  }
  .mt-60 {
    margin-top: 60px;
  }
  .mt-70 {
    margin-top: 70px;
  }
  .mt-75 {
    margin-top: 75px;
  }
  .mt-80 {
    margin-top: 80px;
  }
  .mt-90 {
    margin-top: 90px;
  }
  .mt-100 {
    margin-top: 100px;
  }
  .mt-120 {
    margin-top: 120px;
  }

  .marb-5 {
    margin-bottom: 5px !important;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-25 {
    margin-bottom: 25px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-45 {
    margin-bottom: 45px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .mb-58 {
    margin-bottom: 58px;
  }
  .mb-60 {
    margin-bottom: 60px;
  }
  .mb-70 {
    margin-bottom: 70px;
  }
  .mb-75 {
    margin-bottom: 75px;
  }
  .mb-80 {
    margin-bottom: 80px;
  }
  .mb-90 {
    margin-bottom: 90px;
  }
  .mb-100 {
    margin-bottom: 100px;
  }

  .mt-24 {
    @media (min-width: 768px) {
      margin-top: 24px;
    }
  }
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.image-block {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background: url(/assets/images/login-bg.png);
  img {
    width: 100%;
  }
}

.form-control::placeholder {
  color: #1d1d1d;
  opacity: 1;
  font-size: 13px;
  font-family: $hk-grotesk-regular;
}

.form-control:-ms-input-placeholder {
  color: #1d1d1d;
  font-size: 13px;
  font-family: $hk-grotesk-regular;
}

.form-control::-ms-input-placeholder {
  color: #1d1d1d;
  font-size: 13px;
  font-family: $hk-grotesk-regular;
}

//btn-cta-wrapper
.btn-cta-wrapper {
  @media (max-width: 767px) {
    a {
      &.btn-theme {
        float: left;
        margin-top: 0 !important;
        max-width: 45%;

        & + .btn-theme {
          margin-left: 15px;
        }
      }
    }
  }
}

//Remove focus
button,
a {
  &:focus {
    box-shadow: none !important;
  }
}
