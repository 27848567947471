
.badge {
  border-radius: 3px;
  text-transform: capitalize;
  color: #ffffff;
  margin-right: 10px;
  padding: 2px 9px;
  font-weight: 600;
  //height: 26px;
  font-size: 12px;
  line-height: 1.6;
}

/* YELLOW */
.badge-paid-exchange,
.badge-pending {
  background-color: $orange;
  color: white !important;
}

/* RED */
.badge-refunded,
.badge-cancelled,
.badge-failed,
.badge-rejected,
.badge-unpaid,
.badge-deduction,
.badge-restaurant-closed,
.badge-unprocessed,
.badge-unpaid-exchange,
.badge-revisit-required
{
  background-color: $red;
  color: #FFF !important;
}

/* BLUE */
.badge-on-hold,
.badge-planned,
.badge-assigned,
.badge-reassigned,
.badge-normal,
.badge-processed,
.badge-non-redirected
{
  background-color: $blue;
  color: #FFF !important;
}

.badge-secondary {
  background-color: $gray;
}

/* BLUE - DARK */
.badge-picked,
.badge-is_default
{
  background-color: $blue-alt;
  color: #FFF;
}

/*GRAY*/

.badge-inactive, .badge-default{
  background-color: #e9e9e9;
  color: #333;
  border: 1px solid #ddd;
}
/* GREEN */
.badge-completed,
.badge-success,
.badge-done,
.badge-delivered,
.badge-accepted,
.badge-current,
.badge-restaurant-open,
.badge-active,
.badge-paid,
.badge-redirected,
.badge-approved
{
  background-color: $green;
  color: #FFF !important;
}

/* ORANGE */
.badge-processing
{
  background-color: $orange;
}

/*dark green */
.badge-in_progress
{
  background-color: $green;
  color: #fff;
}

/*.badge-exchange {
  background: $color-exchange;
}
.badge-queuing{
  background: $color-queuing;
}
.badge-dispatch{
  background:$color-dispatch;
}

.badge-confirmed{
  background:$color-confirmed;
}
.badge-cancelled{
  background:$color-cancelled;
}*/

.product-badge {
  text-transform: none;
  margin-left: 33px;
  font-size: 14px;
  height: 29px;
  font-weight: 400;
}