footer {
  background: $theme-color;
  color: $white;
  padding: 130px 0;
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    li {
      a {
        color: $white;
      }
    }
    &.social-media {
      li {
        display: inline-block;
        background: $white;
        width: 50px;
        height: 50px;
        font-size: 30px;
        border-radius: 50%;
        text-align: center;
        padding: 10px 0;
        margin-right: 16px;
        margin-bottom: 12px;
        a {
          color: $theme-color;
          display: block;
          i {
            font-family: 'FontAwesome';
          }
        }
        &:hover {
          a {
            color: $red;
          }
        }
      }
    }
  }
}