.div-input-wrapper {
  display: flex;
}
.login-fail-wrapper {
  padding: 20px;
  background: #e6b8b8;
  h4 {
    margin-bottom: 0;
  }
}

.login-page {
  height: 100vh !important;
}

.bank-login-form {
  .login-logo {
    h3 {
      font-size: 32px;
      line-height: 38px;
      text-transform: uppercase;
      font-family: "Inter Bold";
      text-transform: none;
      letter-spacing: normal;
    }
  }

  .bank-id-logo {
    margin-bottom: 30px;
  }

  .bank-qr-warpper {
    text-align: center;
    margin-bottom: 50px;

    .btn-theme {
      font-size: 16px;
      color: #fff;
      text-align: center;
      border: none;
      width: auto;
      text-decoration: none;
      font-family: "Inter Bold";
      padding: 20px;
      line-height: 20px;
      display: block;
      max-width: none;

      &.btn-blue {
        background: #043a86;
      }

      &.btn-black {
        background: #000000;
      }
    }

    button {
      font-size: 16px;
      text-align: center;
      padding: 20px;
    }
  }

  .content {
    max-width: 285px;
    margin: 0 auto;

    p {
      font-size: 16px;
      line-height: 19px;
      font-family: "Inter Regular";
      color: #000000;
    }
  }

  .login-back-btn {
    a {
      font-size: 16px;
      line-height: 19px;
      font-family: "Inter Regular";
      color: #4e8695;
    }
  }
}
