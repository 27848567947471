.btn-theme {
  font-size: 16px;
  color: #fff;
  text-align: center;
  border: none;
  width: auto;
  text-decoration: none;
  font-family: "Inter Bold";
  padding: 20px;
  line-height: 20px;
  display: block;
  max-width: none !important;

  &.btn-blue {
    background: #043a86;
  }

  &.btn-black {
    background: #000000;
  }
}
.document-info-block {
  &:not(.modal) {
    max-width: 920px;
    padding-top: 35px;

    @media (min-width: 1200px) {
      padding-top: 65px;
    }
  }

  .accordion-block {
    .card {
      background-color: transparent;
      border: 0;
      border-radius: 0;

      & + .card {
        margin-top: 18px;
      }

      &.card-child {
        padding-left: 37px;
        margin-bottom: 20px;
      }

      .card-header {
        border: 1px solid #e6e6e6;
        background-color: transparent;
        // border-left: 7px solid #02275C;
        border-left: 0;
        position: relative;
        border-radius: 0;
        padding: 0;
        &.has-role {
          &::before {
            background: #f50303;
          }
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 7px;
          background: #02275c;
        }
        a {
          display: block;
          color: #000;
          font-family: "Inter Semibold";
          padding: 8px 30px 20px;

          .title-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .title-block {
              .sub-title {
                font-size: 14px;
                line-height: 17px;
              }

              .main-title {
                font-size: 16px;
                line-height: 22px;
              }
            }

            .popover-toggle-btn {
              top: 3px;
              cursor: pointer;
            }

            .icons-block {
              height: 35px;
              cursor: pointer;

              .icons-wrapper {
                span {
                  font-size: 45px;
                }
              }

              .icons-text-wrapper {
                display: none;

                .span-text {
                  margin-right: 7px;
                }
              }
            }

            .icons-block[aria-expanded="true"] {
              display: flex;
              align-items: flex-end;

              .icons-wrapper {
                display: none;
              }

              .icons-text-wrapper {
                display: block;
              }
            }
          }
        }
      }

      .card-body {
        padding: 20px 0;

        .text-content {
          padding: 0 30px 0 37px;

          h3 {
            font-size: 14px;
            line-height: 17px;
            font-family: "Inter Semibold";
            margin-bottom: 10px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            font-family: "Inter Semibold";
            margin: 0;
          }
        }

        .file-list-block {
          .single-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            line-height: 18px;
            font-family: "Inter Semibold";
            color: #000;
            padding: 20px 30px 20px 37px;

            a {
              color: #000;
            }

            .file-name {
              span {
                margin-right: 8px;
                font-size: 20px;
              }
            }

            & + .single-list {
              border-top: 1px solid #e6e6e6;
            }
          }
        }
      }
    }

    &.new-filter,
    &.new-folder {
      .main-title {
        padding: 15px 0;
        color: #000;

        &:hover {
          color: inherit;
        }

        h5 {
          font-size: 16px;
          line-height: 24px;
          font-family: "Inter Semibold";
          margin: 0;

          span {
            font-size: 13px;
            margin-left: 5px;
            transform: rotate(180deg);
            display: inline-block;
            transition: 0.3s all ease;
          }
        }

        &[aria-expanded="true"] {
          h5 {
            span {
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    &.new-folder {
      border-bottom: 1px solid #e6e6e6;

      .main-content {
        padding: 20px;
        border: 1px solid #e6e6e6;
        border-left: 8px solid #02275c;

        .btn-theme {
          width: 100%;
        }
      }
    }

    &.new-filter {
      margin-bottom: 20px;
      .main-content {
        padding-bottom: 5px;
        border-bottom: 1px solid #e6e6e6;
        .filter-block-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .filter-block {
            list-style: none;
            padding: 0;
            margin: 0 15px 0 0;

            li {
              display: inline-block;
              color: transparentize(#043a86, $amount: 0.4);
              border: 1px solid;
              border-radius: 19px;
              font-size: 16px;
              font-family: "Inter Semibold";
              padding: 6px 20px;
              cursor: pointer;
              margin-bottom: 15px;
              transition: 0.3s all ease;

              &:not(:last-child) {
                margin-right: 15px;
              }

              &.active,
              &:hover {
                background: #043a86;
                border-color: #043a86;
                color: #fff;
              }
            }
          }

          .button-wrapper {
            button {
              border: 0;
              background: transparent;
              font-size: 16px;
              font-family: "Inter Semibold";
              color: #000;
              text-decoration: underline;
              white-space: nowrap;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .form-group
    [class*="css-"][class*="-container"]
    [class*="-control"]
    [class*="-IndicatorsContainer"] {
    background: none;
    position: relative;
    &::after {
      position: absolute;
      content: "\e902";
      top: 5px;
      right: 1px;
      display: inline-block;
      transform: rotate(180deg);
      font-family: "oisf" !important;
      font-size: 14px;
    }
  }

  .form-group {
    border: 0;
    padding: 0;
    &.has-error {
      border: 0;
    }
    &:focus-within {
      border: 0 !important;
      padding: 0 !important;
    }

    label {
      display: none;
      font-family: "Inter Semibold";
    }

    .form-control {
      border-radius: 3px;
      height: 50px;
      border: 1px solid transparentize(#043a86, 0.4);
      padding: 15px;
      font-size: 14px;
      color: #000;
      font-family: "Inter Semibold";

      &::placeholder {
        font-size: 14px;
        color: #000;
        font-family: "Inter Semibold";
      }
    }

    textarea {
      padding: 15px !important;
      height: 93px !important;
    }

    .input-file-wrapper {
      z-index: 0;
      background: #f9f9f9;
    }

    .file-list {
      margin-top: 15px;

      li {
        &:before {
          display: none;
        }

        & + li {
          margin-top: 10px;
        }

        span {
          color: #043a86 !important;
          font-size: 14px;
          font-family: "Inter Semibold";
          text-decoration: underline;

          i {
            color: #043a86 !important;
          }
        }
      }
    }
  }
  .basic-multi-select {
    border-radius: 25px;
    min-height: 50px;
    border: 1px solid rgba(4, 58, 134, 0.6);
    font-size: 14px;
    padding: 5px 15px;
    color: #000;
    font-family: "Inter Semibold";

    .css-1wa3eu0-placeholder {
      font-size: 14px;
      color: #000;
      font-family: "Inter Semibold";
    }
  }

  .select-field {
    min-height: 50px;
    border: 1px solid rgba(4, 58, 134, 0.6);
    font-size: 14px;
    padding: 5px 15px;
    color: #000;
    font-family: "Inter Semibold";

    .select__menu {
      padding-left: 0px;
      &.css-26l3qy-menu {
        z-index: 1;
      }
    }
    .css-1uccc91-singleValue {
      font-size: 14px;
      color: #000;
      font-family: "Inter Semibold";
    }
    .css-1wa3eu0-placeholder {
      font-size: 14px;
      color: #000;
      font-family: "Inter Semibold";
    }
  }

  .select__menu {
    &.css-26l3qy-menu {
      z-index: 9;
    }
  }

  .custom-select-container {
    border-radius: 25px;
    height: 50px;
    border: 1px solid transparentize(#043a86, 0.4);
    font-size: 14px;
    color: #000;
    font-family: "Inter Semibold";
    margin-bottom: 0;

    .selected-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;

      .selected-category {
        padding-right: 10px;
      }

      .icon-up-arrow {
        display: inline-block;
        transition: 0.3s all ease;
        transform: rotate(180deg);
      }
    }

    .dropdown-container-custom {
      background: #fff;
      border: 1px solid transparentize(#043a86, 0.4);
      border-top: 0;
      position: relative;
      z-index: 9;
      border-radius: 0 0 25px 25px;
      padding: 5px 15px 20px;
      width: calc(100% + 2px);
      left: -1px;

      .input-group {
        border-radius: 25px;
        border: 1px solid transparentize(#043a86, 0.4);
        height: 50px;
        margin-bottom: 20px;

        input {
          border: 0;
          height: 50px;
          background-color: transparent;
          padding: 15px;
          font-size: 14px;
          color: #000;
          font-family: "Inter Semibold";

          &::placeholder {
            font-size: 14px;
            color: #000;
            font-family: "Inter Semibold";
          }
        }

        .input-group-append {
          button {
            border-radius: 25px;
            background-color: #043a86;
            border-color: #043a86;
            position: relative;
            left: 1px;
          }
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        max-height: 150px;
        overflow-y: auto;

        li {
          font-size: 14px;
          color: #000;
          font-family: "Inter Semibold";
          cursor: pointer;

          & + li {
            margin-top: 15px;
          }
        }
      }
    }

    &.active {
      border-radius: 25px 25px 0 0;

      .selected-field {
        .icon-up-arrow {
          transform: rotate(0deg);
        }
      }
    }
  }

  span.error-message {
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ff4628;
    font-family: "Inter Semibold";
    margin-bottom: 0;
    display: block;
  }

  .action-block {
    display: flex;
    align-items: center;

    .popover-toggle-btn {
      margin-right: 20px;
      position: relative;

      &[aria-describedby*="action-"] {
        z-index: 99999;
      }
    }
  }
}
.popover {
  inset: -40px -20px auto auto !important;
  padding: 40px 15px 10px 15px;
  margin-top: 0 !important;
  min-width: 200px;
  border-color: #e6e6e6;
  border-radius: 0;
  .popover-body {
    border-top: 1px solid #e6e6e6;
    padding: 8px 0;
    font-size: 16px;
    font-family: "Inter Semibold";
    .delete-btn-custom,
    .file-delete-btn {
      color: #ed5757;
    }
  }
}
