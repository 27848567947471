//Font Family
@font-face {
  font-family: 'HKGrotesk-Light';
  src: url('../../fonts/HKGrotesk-Light.eot');
  src: url('../../fonts/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/HKGrotesk-Light.woff2') format('woff2'),
  url('../../fonts/HKGrotesk-Light.woff') format('woff'),
  url('../../fonts/HKGrotesk-Light.ttf') format('truetype'),
  url('../../fonts/HKGrotesk-Light.svg#HKGrotesk-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'HKGrotesk-Regular';
  src: url('../../fonts/HKGrotesk-Regular.eot');
  src: url('../../fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/HKGrotesk-Regular.woff2') format('woff2'),
  url('../../fonts/HKGrotesk-Regular.woff') format('woff'),
  url('../../fonts/HKGrotesk-Regular.ttf') format('truetype'),
  url('../../fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk-Medium';
  src: url('../../fonts/HKGrotesk-Medium.eot');
  src: url('../../fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/HKGrotesk-Medium.woff2') format('woff2'),
  url('../../fonts/HKGrotesk-Medium.woff') format('woff'),
  url('../../fonts/HKGrotesk-Medium.ttf') format('truetype'),
  url('../../fonts/HKGrotesk-Medium.svg#HKGrotesk-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HKGrotesk-Bold';
  src: url('../../fonts/HKGrotesk-Bold.eot');
  src: url('../../fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/HKGrotesk-Bold.woff2') format('woff2'),
  url('../../fonts/HKGrotesk-Bold.woff') format('woff'),
  url('../../fonts/HKGrotesk-Bold.ttf') format('truetype'),
  url('../../fonts/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Medium Italic';
  src: url('../../fonts/Inter18pt-MediumItalic.eot');
  src: url('../../fonts/Inter18pt-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../../fonts/Inter18pt-MediumItalic.woff2') format('woff2'), url('../../fonts/Inter18pt-MediumItalic.woff') format('woff'), url('../../fonts/Inter18pt-MediumItalic.ttf') format('truetype'), url('../../fonts/Inter18pt-MediumItalic.svg#Inter18pt-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Inter Regular';
  src: url('../../fonts/Inter18pt-Regular.eot');
  src: url('../../fonts/Inter18pt-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Inter18pt-Regular.woff2') format('woff2'), url('../../fonts/Inter18pt-Regular.woff') format('woff'), url('../../fonts/Inter18pt-Regular.ttf') format('truetype'), url('../../fonts/Inter18pt-Regular.svg#Inter18pt-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter Bold';
  src: url('../../fonts/Inter18pt-Bold.eot');
  src: url('../../fonts/Inter18pt-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Inter18pt-Bold.woff2') format('woff2'), url('../../fonts/Inter18pt-Bold.woff') format('woff'), url('../../fonts/Inter18pt-Bold.ttf') format('truetype'), url('../../fonts/Inter18pt-Bold.svg#Inter18pt-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter Semibold';
  src: url('../../fonts/Inter18pt-SemiBold.eot');
  src: url('../../fonts/Inter18pt-SemiBold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Inter18pt-SemiBold.woff2') format('woff2'), url('../../fonts/Inter18pt-SemiBold.woff') format('woff'), url('../../fonts/Inter18pt-SemiBold.ttf') format('truetype'), url('../../fonts/Inter18pt-SemiBold.svg#Inter18pt-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter Light';
  src: url('../../fonts/Inter18pt-Light.eot');
  src: url('../../fonts/Inter18pt-Light.eot?#iefix') format('embedded-opentype'), url('../../fonts/Inter18pt-Light.woff2') format('woff2'), url('../../fonts/Inter18pt-Light.woff') format('woff'), url('../../fonts/Inter18pt-Light.ttf') format('truetype'), url('../../fonts/Inter18pt-Light.svg#Inter18pt-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'oisf';
  src: url('../../fonts/oisf.eot?6g4q2r');
  src: url('../../fonts/oisf.eot?6g4q2r#iefix') format('embedded-opentype'), url('../../fonts/oisf.ttf?6g4q2r') format('truetype'), url('../../fonts/oisf.woff?6g4q2r') format('woff'), url('../../fonts/oisf.svg?6g4q2r#oisf') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'oisf' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-doc:before {
  content: "\e900";
}
.icon-long-arrow:before {
  content: "\e901";
}
.icon-up-arrow:before {
  content: "\e902";
}
