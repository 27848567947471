.collapse-block-wrapper {
  h2 {
    margin-bottom: 20px;
  }



  h6 {
    margin-bottom: 5px;
  }



  .collapse-block {
    margin-top: 28px;



    .single-collapse {
      .collapse-title {
        border: 1px solid transparentize(#b7b7b7, .5);
        border-left: 4px solid #000;
        padding: 6px 20px;
        position: relative;
        &[aria-expanded="true"] {
          border-left-color: #FF4628;
          .right-content {
            .on-close {
              display: none;
            }
            .on-open {
              display: block;
            }
          }
        }



        h6 {
          margin-bottom: 0;
          padding-bottom: 15px;



          p {
            font-weight: 400;
            margin-bottom: 0;
          }




        }



        .right-content {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 25px;



          .on-open {
            display: none;



            h6 {
              font-size: 12px;
              line-height: 16px;
              display: inline-block;
              padding-bottom: 0;
              margin-right: 5px;
            }



            img {
              display: inline-block;
            }
          }
        }
        .remove-button-wrapper {
          position: absolute;
          right: -27px;
          top: 50%;
          transform: translateY(-50%);
        }
      }



      .collapse-content-wrapper {
        padding: 24px 0;
        .content-text-block {
          padding: 0 24px;
        }
        table {
          margin-bottom: 0;
          tbody {
            tr {
              background-color: transparent !important;
              td {
                font-size: 10px;
                line-height: 14px;
                border-top: 1px solid rgba(112,112,112,.44);
                padding-top: 14px;
                padding-bottom: 14px;
                vertical-align: middle;
                &:first-child {
                  font-family: HKGrotesk-Bold;
                  padding-left: 24px;
                  img {
                    margin-right: 5px;
                  }
                }
                &:last-child {
                  text-align: right;
                  padding-right: 24px;
                  &:hover {
                    color: #FF4628;
                    a {
                      color: #FF4628;
                    }
                  }
                }
              }
              &:first-child {
                td {
                  border: none;
                }
              }
            }
          }
        }
        .button-wrapper {
          padding: 0 24px;



        }
      }



      & + .single-collapse {
        margin-top: 15px;
      }



      &.single-collapse-alt {
        .collapse-title {
          margin-right: 40px;
        }
        .collapse-content-wrapper {
          padding-right: 40px;
          table {
            tbody {
              tr {
                td {
                  &:last-child {
                    position: relative;
                    .remove-button-wrapper {
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      right: -27px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .btn-theme.btn-black {
    background: #000;
    width: 150px;
  }
}