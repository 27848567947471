.btn-theme {
  font-size: 13px;
  color: $white;
  text-align: center;
  border: none;
  width: 100%;
  text-decoration: none;
  font-family: $hk-grotesk-medium;
  padding: 10px 10px;
  line-height: 13px;
  display: block;
  &.btn-gray {
    background: $gray1;
    max-width: 176px;
    &:hover {
      color: $white;
    }
  }
  &.btn-light-gray {
    background: $gray;
    max-width: 176px;
    color: $theme-color;
    &:hover {

    }
  }

}

.inline-buttons {
  .btn-theme {
    margin-right: 12px;
    margin-bottom: 12px;
  }
}

.btn-success {
  background-color: $green;
  border-color: $green;
}

.btn-warning {
  background-color: $orange;
  border-color: $orange;
  color: white;
  &:hover {
    color: white;
  }
}

.btn-secondary {
  background-color: $gray;
  border-color: $gray;
}

.btn-red,
.btn-danger {
  background-color: $red;
  border-color: $red;
  color: white;
  &:hover {
    color: white;
  }
}

