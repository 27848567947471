body {
  &.new-design {
    font-family: "Inter Regular";
    border: 0;

    p {
      font-family: "Inter Regular";
    }

    .btn-theme {
      font-size: 16px;
      color: #fff;
      text-align: center;
      border: none;
      width: auto;
      text-decoration: none;
      font-family: "Inter Bold";
      padding: 20px;
      line-height: 20px;
      display: block;
      max-width: none;

      &.btn-blue {
        background: #043a86;
      }

      &.btn-black {
        background: #000000;
      }
    }

    .sidebar {
      padding: 40px 35px 30px;
      display: flex;
      flex-direction: column;
      background: #02275c;
      border: 0;

      .logo {
        .logo-text {
          font-size: 16px;
          line-height: 21px;
          color: #f4c868;
          margin-bottom: 10px;
        }

        img {
          width: 100%;
          max-width: 160px;
        }
      }

      ul {
        li {
          a {
            font-family: "Inter Regular";

            @media (min-width: 1600px) {
              font-size: 20px;
              line-height: 24px;
            }
          }

          &.active {
            a {
              color: #fff;
            }
          }
        }

        &.upper-menu {
          flex: 1;
          margin-top: 40px;
        }

        &.lower-menu {
          position: static;
        }
      }
    }

    .content-area {
      margin: 0;

      @media (max-width: 991px) {
        margin-top: 53px;
      }

      .header-title {
        padding: 75px 20px;
        background: linear-gradient(90deg, #08397c 0%, #002353 100%);

        h1 {
          font-size: 56px;
          line-height: 68px;
          font-family: "Inter Light";
          color: #fff;
          margin: 0;

          @media (max-width: 1199px) {
            font-size: 44px;
            line-height: 54px;
          }

          @media (max-width: 767px) {
            font-size: 32px;
            line-height: 40px;
          }

          strong {
            color: #f4c868;
            font-family: "Inter Bold";
          }
        }

        p {
          font-size: 26px;
          line-height: 31px;
          font-family: "Inter Medium Italic";
          color: #f4c868;
          margin: 0;

          @media (max-width: 1199px) {
            font-size: 23px;
            line-height: 28px;
          }

          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 25px;
          }
        }
      }

      .main-text-block {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 50px;

        .title-block {
          h2 {
            font-size: 52px;
            line-height: 63px;
            font-family: "Inter Semibold";

            @media (max-width: 1199px) {
              font-size: 40px;
              line-height: 50px;
            }

            @media (max-width: 767px) {
              font-size: 26px !important;
              line-height: 35px;
            }

            span {
              font-family: "Inter Regular";
            }
          }
        }
      }

      .document-info-block {
        max-width: 920px;
        padding-top: 35px;

        @media (min-width: 1200px) {
          padding-top: 65px;
        }

        .accordion-block {
          .card {
            background-color: transparent;
            border: 0;
            border-radius: 0;

            & + .card {
              margin-top: 18px;
            }

            .card-header {
              border: 1px solid #e6e6e6;
              background-color: initial;
              // background-color: transparent;
              // border-left: 7px solid #02275C;
              border-left: 0;
              border-radius: 0;
              position: relative;
              padding: 0;

              a {
                display: block;
                color: #000;
                font-family: "Inter Semibold";
                padding: 8px 30px 20px;

                .title-wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-end;

                  .title-block {
                    .sub-title {
                      font-size: 14px;
                      line-height: 17px;
                    }

                    .main-title {
                      font-size: 16px;
                      line-height: 22px;
                    }
                  }

                  .popover-toggle-btn {
                    top: 3px;
                    cursor: pointer;
                  }

                  .icons-block {
                    height: 35px;
                    cursor: pointer;

                    .icons-wrapper {
                      span {
                        font-size: 45px;
                      }
                    }

                    .icons-text-wrapper {
                      display: none;

                      .span-text {
                        margin-right: 7px;
                      }
                    }
                  }

                  .icons-block[aria-expanded="true"] {
                    display: flex;
                    align-items: flex-end;

                    .icons-wrapper {
                      display: none;
                    }

                    .icons-text-wrapper {
                      display: block;
                    }
                  }
                }
              }
            }

            .card-body {
              padding: 20px 0;

              .text-content {
                padding: 0 30px 0 37px;

                h3 {
                  font-size: 14px;
                  line-height: 17px;
                  font-family: "Inter Semibold";
                  margin-bottom: 10px;
                }

                p {
                  font-size: 16px;
                  line-height: 24px;
                  font-family: "Inter Semibold";
                  margin: 0;
                }
              }

              .file-list-block {
                .single-list {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;
                  line-height: 18px;
                  font-family: "Inter Semibold";
                  color: #000;
                  padding: 20px 30px 20px 37px;

                  a {
                    color: #000;
                  }

                  .file-name {
                    span {
                      margin-right: 8px;
                      font-size: 20px;
                    }
                  }

                  & + .single-list {
                    border-top: 1px solid #e6e6e6;
                  }
                }
              }
            }
          }

          &.new-filter,
          &.new-folder {
            .main-title {
              padding: 15px 0;
              color: #000;

              &:hover {
                color: inherit;
              }

              h5 {
                font-size: 16px;
                line-height: 24px;
                font-family: "Inter Semibold";
                margin: 0;

                span {
                  font-size: 13px;
                  margin-left: 5px;
                  transform: rotate(180deg);
                  display: inline-block;
                  transition: 0.3s all ease;
                }
              }

              &[aria-expanded="true"] {
                h5 {
                  span {
                    transform: rotate(0deg);
                  }
                }
              }
            }
          }

          &.new-folder {
            border-bottom: 1px solid #e6e6e6;

            .main-content {
              padding: 20px;
              border: 1px solid #e6e6e6;
              border-left: 8px solid #02275c;

              .btn-theme {
                width: 100%;
              }
            }
          }

          &.new-filter {
            margin-bottom: 20px;
            .main-content {
              padding-bottom: 5px;
              border-bottom: 1px solid #e6e6e6;
              .filter-block-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .filter-block {
                  list-style: none;
                  padding: 0;
                  margin: 0 15px 0 0;

                  li {
                    display: inline-block;
                    color: transparentize(#043a86, $amount: 0.4);
                    border: 1px solid;
                    border-radius: 19px;
                    font-size: 16px;
                    font-family: "Inter Semibold";
                    padding: 6px 20px;
                    cursor: pointer;
                    margin-bottom: 15px;
                    transition: 0.3s all ease;

                    &:not(:last-child) {
                      margin-right: 15px;
                    }

                    &.active,
                    &:hover {
                      background: #043a86;
                      border-color: #043a86;
                      color: #fff;
                    }
                  }
                }

                .button-wrapper {
                  button {
                    border: 0;
                    background: transparent;
                    font-size: 16px;
                    font-family: "Inter Semibold";
                    color: #000;
                    text-decoration: underline;
                    white-space: nowrap;
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }
        }

        .form-group {
          border: 0;
          padding: 0;

          label {
            display: none;
            font-family: "Inter Semibold";
          }

          .form-control {
            border-radius: 3px;
            height: 50px;
            border: 1px solid transparentize(#043a86, 0.4);
            padding: 15px;
            font-size: 14px;
            color: #000;
            font-family: "Inter Semibold";

            &::placeholder {
              font-size: 14px;
              color: #000;
              font-family: "Inter Semibold";
            }
          }

          textarea {
            padding: 15px !important;
            height: 93px !important;
          }

          .input-file-wrapper {
            background: #f9f9f9;
          }

          .file-list {
            margin-top: 15px;

            li {
              &:before {
                display: none;
              }

              & + li {
                margin-top: 10px;
              }

              span {
                color: #043a86 !important;
                font-size: 14px;
                font-family: "Inter Semibold";
                text-decoration: underline;

                i {
                  color: #043a86 !important;
                }
              }
            }
          }
        }

        .custom-select-container {
          border-radius: 25px;
          height: 50px;
          border: 1px solid transparentize(#043a86, 0.4);
          font-size: 14px;
          color: #000;
          font-family: "Inter Semibold";
          margin-bottom: 15px;

          .selected-field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;

            .selected-category {
              padding-right: 10px;
            }

            .icon-up-arrow {
              display: inline-block;
              transition: 0.3s all ease;
              transform: rotate(180deg);
            }
          }

          .dropdown-container-custom {
            background: #fff;
            border: 1px solid transparentize(#043a86, 0.4);
            border-top: 0;
            position: relative;
            z-index: 9;
            border-radius: 0 0 25px 25px;
            padding: 5px 15px 20px;
            width: calc(100% + 2px);
            left: -1px;

            .input-group {
              border-radius: 25px;
              border: 1px solid transparentize(#043a86, 0.4);
              height: 50px;
              margin-bottom: 20px;

              input {
                border: 0;
                height: 50px;
                background-color: transparent;
                padding: 15px;
                font-size: 14px;
                color: #000;
                font-family: "Inter Semibold";

                &::placeholder {
                  font-size: 14px;
                  color: #000;
                  font-family: "Inter Semibold";
                }
              }

              .input-group-append {
                button {
                  border-radius: 25px;
                  background-color: #043a86;
                  border-color: #043a86;
                  position: relative;
                  left: 1px;
                }
              }
            }

            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              height: 100%;
              max-height: 150px;
              overflow-y: auto;

              li {
                font-size: 14px;
                color: #000;
                font-family: "Inter Semibold";
                cursor: pointer;

                & + li {
                  margin-top: 15px;
                }
              }
            }
          }

          &.active {
            border-radius: 25px 25px 0 0;

            .selected-field {
              .icon-up-arrow {
                transform: rotate(0deg);
              }
            }
          }
        }

        span.error-message {
          font-size: 10px;
          line-height: 14px;
          text-transform: uppercase;
          color: #ff4628;
          font-family: "Inter Semibold";
          margin-bottom: 0;
        }

        .action-block {
          display: flex;
          align-items: center;

          .popover-toggle-btn {
            margin-right: 20px;
            position: relative;

            &[aria-describedby*="action-"] {
              z-index: 99999;
            }
          }
        }
      }

      .my-account-block {
        max-width: 800px;
        padding-top: 35px;

        @media (min-width: 1200px) {
          padding-top: 65px;
        }

        .custom-form-block-wrapper {
          @media (min-width: 768px) {
            .custom-form-row {
              display: flex;
              margin: 0 -12px;

              .col-half {
                flex: 0 0 50%;
                padding: 0 12px;
              }
            }
          }

          .form-input-wrapper {
            margin-bottom: 30px;

            label {
              font-size: 16px;
              line-height: 20px;
              color: #000;
              font-family: "Inter Semibold";
              margin: 0;
            }

            input {
              border: none;
              border-bottom: 2px solid #000;
              background-color: transparent;
              width: 100%;
              height: 50px;
              font-size: 16px;
              color: #000;
              padding: 8px 0;
              font-family: "Inter Regular";

              &::-webkit-input-placeholder {
                color: #000;
              }

              &:-ms-input-placeholder {
                color: #000;
              }

              &::placeholder {
                color: #000;
              }

              &:focus {
                outline: none;
              }
            }
          }

          form {
            margin: 30px auto 0;

            button[type="submit"] {
              margin-top: 30px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }

    .login-form {
      .login-logo {
        h3 {
          font-size: 32px;
          line-height: 38px;
          text-transform: uppercase;
          font-family: "Inter Bold";
          letter-spacing: 3.8px;
        }

        h4 {
          font-size: 28px;
          line-height: 34px;
          font-family: "Inter Bold";
          color: #f4c868;
        }
      }

      p {
        &.login-account {
          font-size: 28px;
          line-height: 34px;
          font-family: "Inter Bold";
          margin-bottom: 50px;
          display: block;
        }

        &.account-sign-up {
          font-size: 28px;
          line-height: 34px;
          font-family: "Inter Bold";
          margin-bottom: 20px;
          display: block;
        }
      }
    }

    .bank-login-btn-wrapper {
      a {
        display: block;

        img {
          margin-left: 10px;
        }
      }
    }

    .form-group {
      border-left: 8px solid #043a86;

      &:focus-within {
        border-left: 8px solid #043a86;
        padding-left: 20px;
      }
    }

    .bank-login-form {
      .login-logo {
        h3 {
          text-transform: none;
          letter-spacing: normal;
        }
      }

      .bank-id-logo {
        margin-bottom: 30px;
      }

      .bank-qr-warpper {
        text-align: center;
        margin-bottom: 50px;
      }

      .content {
        max-width: 285px;
        margin: 0 auto;

        p {
          font-size: 16px;
          line-height: 19px;
          font-family: "Inter Regular";
          color: #000000;
        }
      }

      .login-back-btn {
        a {
          font-size: 16px;
          line-height: 19px;
          font-family: "Inter Regular";
          color: #4e8695;
        }
      }
    }

    .div-otp-wrapper {
      & > div {
        align-items: center;
        justify-content: center;
      }

      input {
        border-radius: 3px;
        border: 1px solid #e8e8e8;
        font-family: "Inter Bold";
      }
    }

    .logo-block {
      .content-block {
        h1 {
          font-size: 180px;
          line-height: 216px;
          color: #ffffff;
          font-family: "Inter Bold";

          @media (max-width: 1199px) {
            font-size: 130px;
            line-height: 150px;
          }
        }
      }
    }
    .popover {
      inset: -40px -20px auto auto !important;
      padding: 40px 15px 10px 15px;
      margin-top: 0 !important;
      min-width: 200px;
      border-color: #e6e6e6;
      border-radius: 0;
      .popover-body {
        border-top: 1px solid #e6e6e6;
        padding: 8px 0;
        font-size: 16px;
        font-family: "Inter Semibold";
        .delete-btn-custom,
        .file-delete-btn {
          color: #ed5757;
        }
      }
    }
  }
}
