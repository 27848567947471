.sidebar-title {
  margin-top: 30px;

  h4 {
    color: $white;
    font-size: 16px;
    margin-bottom: 0;
    letter-spacing: -0.7px;
    line-height: normal;

    + h4 {
      margin-top: 5px;
    }
  }
}

.pagination-buttons {
  .pagination {
    li.page-item {
      margin-right: 8px;
      a.page-link {
        cursor: pointer;
        padding: 0.1rem 0.45rem 0.3rem;
        &.item-red {
          border: 1px solid $red;
          color: $red;
          font-weight: bold;
        }
      }
    }
  }
}

.custom-modal{
  ul {
    >li{
      list-style: none;
      border-bottom:1px solid #eee;
    }
  }
}

.s-alert-box {
  z-index: 9999 !important;
}


.remove-file {
  color: $red;
  cursor: pointer;
  padding-left: 10px;
}

.th-sortable {
  cursor: pointer;
}

.table thead tr th {
  &.th-active-asc {
    background-color: #ccc;
    &:after {
      font-family: "Font Awesome 5 Free";
      content: "\f078";
      display: inline-block;
      font-weight: 900;
      margin-left: 10px;
    }
  }
  &.th-active-desc {
    background-color: #ccc;
    &:after {
      font-family: "Font Awesome 5 Free";
      content: "\f077";
      display: inline-block;
      font-weight: 900;
      margin-left: 10px;
    }
  }
}

h2 {
  font-size: 30px;
}

.order-detail-page {
  h6 {
    letter-spacing: 0.4px;
  }
  p {
    margin-top: 8px;
  }
}