@import "autoload/bootstrap";
@import "autoload/slick-theme";
@import "autoload/slick";
@import "common/fonts";
@import "common/variables";
@import "common/mixins";

/** Import theme styles */
@import "common/global";
@import "components/buttons";
@import "components/badges";
@import "components/general";
@import "components/alert";
@import "components/infinite_dropdown";
@import "layouts/header";
@import "layouts/heroBanner";
@import "layouts/footer";
@import "layouts/layout";
@import "layouts/fileLayout";
@import "components/dump";
@import "components/additional";
@import "components/new-design";

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

